import "../styles/Vehicles.scss"

const Vehicles = () => {
    const vehiclesArray = [{
        img: "/assets/img/vehicle/icons8-lebensmittel-liefern-100.png",
        title: "Fuso Absetzkipper",
        information: "Der Fuso Absetzkipper ist ein äußerst wendiges Fahrzeug, das speziell für Arbeiten in beengten städtischen Umgebungen konzipiert wurde. Mit seiner kompakten Bauweise ist er ideal geeignet für kleine und schmale Einfahrten.",
        informationBig: "Der Fuso Absetzkipper ist ein äußerst wendiges Fahrzeug, das speziell für Arbeiten in beengten städtischen Umgebungen konzipiert wurde. Mit seiner kompakten Bauweise ist er ideal geeignet für kleine und schmale Einfahrten. Perfekt für enge Zufahrtswege, in denen größere Fahrzeuge Schwierigkeiten haben könnten. Der Fuso meistert selbst die herausforderndsten Winkel und ist besonders hilfreich in dicht bebauten Wohngebieten oder Stadtzentren, wo Platz oft Mangelware ist. Dieses Fahrzeug bietet nicht nur Mobilität und Flexibilität, sondern auch die notwendige Kraft und Zuverlässigkeit, um selbst anspruchsvolle Abschlepp- und Kippaufgaben effizient zu erledigen.",
        bulletPoints: ["Wendiges Fahrzeug für städtische Umgebungen", "Kompakte Bauweise, ideal für kleine und schmale Einfahrten", "Perfekt für enge Zufahrtswege und dicht bebaute Wohngebiete", "Bietet Mobilität, Flexibilität und Zuverlässigkeit für anspruchsvolle Aufgaben"]
    }, {
        img: "/assets/img/vehicle/icons8-lkw-100.png",
        title: "DAF 3 Achser Absetzkipper",
        information: "Unser DAF 3-Achser Abeatzkipper steht für robuste Leistung und Vielseitigkeit im Einsatz. Dieser Lastwagen ist eine exzellente Wahl für eine Vielzahl von Entsorgungsprojekten und bietet die notwendige Stabilität und Tragkraft für generelle Abfallbeseitigung.",
        informationBig: "Unser DAF 3-Achser Abeatzkipper steht für robuste Leistung und Vielseitigkeit im Einsatz. Dieser Lastwagen ist eine exzellente Wahl für eine Vielzahl von Entsorgungsprojekten und bietet die notwendige Stabilität und Tragkraft für generelle Abfallbeseitigung. Von der Baustellenräumung bis hin zur regelmäßigen Abfallentsorgung in Wohn- und Gewerbegebieten – der DAF 3-Achser ist darauf ausgelegt, große Mengen an Abfällen sicher und effizient zu transportieren. Mit seiner hohen Nutzlast und den robusten Kippmechanismen bewältigt er auch große Entsorgungsprojekte mit Leichtigkeit. Seine hervorragende Geländegängigkeit und starke Achskonfiguration machen ihn zu einem unverzichtbaren Werkzeug auf Baustellen und in der Stadtlogistik.",
        bulletPoints: ["Robuste Leistung und Vielseitigkeit im Einsatz", "Ideal für eine Vielzahl von Entsorgungsprojekten", "Bietet Stabilität und Tragkraft für die generelle Abfallbeseitigung", "Effizienter Transport großer Abfallmengen", "Hervorragende Geländegängigkeit und starke Achskonfiguration"]
    }, {
        img: "/assets/img/vehicle/icons8-lieferung-100.png",
        title: "Brückenwagen - Kranwagen mit Greifer",
        information: "Der Brückenwagen ist ein vielseitiges Arbeitsgerät, das speziell für schwere Lasten und sperrige Güter ausgelegt ist. Mit seiner speziellen Konstruktion und der flexiblen Einsatzfähigkeit ist er optimal geeignet für Maschinenentsorgung.",
        informationBig: "Der Brückenwagen ist ein vielseitiges Arbeitsgerät, das speziell für schwere Lasten und sperrige Güter ausgelegt ist. Mit seiner speziellen Konstruktion und der flexiblen Einsatzfähigkeit ist er optimal geeignet für Maschinenentsorgung. Ob alte Maschinen oder schweres Gerät – der Brückenwagen ist darauf spezialisiert, sperrige und schwere Maschinen sicher und effizient zu transportieren und zu entsorgen. Seine Tragkraft und Stabilität sorgen dafür, dass selbst die größten Herausforderungen mühelos bewältigt werden können. Außerdem spielt der Brückenwagen eine entscheidende Rolle bei der Sammlung und dem Transport von Metallschrott. Dank seiner robusten Plattform und dem leistungsstarken Antrieb kann er große Mengen Schrott auf einmal aufnehmen, was ihn ideal für Recyclingprojekte und Schrottplätze macht.",
        bulletPoints: ["Vielseitiges Arbeitsgerät für schwere Lasten und sperrige Güter", "Optimal geeignet für Maschinenentsorgung und schweres Gerät", "Hohe Tragkraft und Stabilität für die Bewältigung großer Herausforderungen", "Effiziente Sammlung und Transport von Metallschrott", "Robuste Plattform und leistungsstarker Antrieb"]
    }]
    return (<div className="bg-gray">
        <div className="container spacing-section vehicles">
            <h2>Unser Fahrzeugpark</h2>
            <div className="row">
                {vehiclesArray.map((vehicle, index) => (<div className="col-12 col-lg-4" key={index}>
                    <h3>{vehicle.title}</h3>
                    <small>{vehicle.information}</small>
                    {/*<img src={vehicle.img} alt="truck"/>*/}
                    <h4>Geeignet für:</h4>
                    <ul>
                        {vehicle.bulletPoints.map((info, liIndex) => (<li key={liIndex}>{info}</li>))}
                    </ul>
                </div>))}
            </div>
        </div>
    </div>);
};

export default Vehicles;
