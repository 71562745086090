import Hero from "../components/hero";
import WasteList from "../components/WasteList";
import BannerSection from "../components/BannerSection";
import ContainerSize from "../components/ContainerSize";
import Vehicles from "../components/Vehicles";
import History from "../components/History";
import ContainerFill from "../components/ContainerFill";

const Home = () => {
    return (
        <>
            <Hero src="https://images.pexels.com/photos/209251/pexels-photo-209251.jpeg"/>

            <WasteList/>

            <BannerSection
                headline="Kontak&shy;tieren Sie uns"
                subline="Wir freuen uns auf Ihre Nachricht"
                buttonText="Jetzt anrufen +49 1726 30 18 92"
                href="tel:+491726301892"
            />

            {/*<InfoBoxGrid />*/}


            {/*<CenteredText*/}
            {/*    subheadline="Subheadline Text"*/}
            {/*    headline="Main Headline"*/}
            {/*    text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque nisl eros, pulvinar facilisis justo mollis, auctor consequat urna. Morbi a bibendum metus."*/}
            {/*/>*/}

            <ContainerSize />

            <ContainerFill />

            <Vehicles />

            <History />

            <BannerSection
                headline="Kontak&shy;tieren Sie uns"
                subline="Wir freuen uns auf Ihre Nachricht"
                buttonText="Jetzt anrufen +49 1726 30 18 92"
                href="tel:+491726301892"
            />

        </>
    );
};

export default Home;
