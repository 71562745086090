import '../../styles/Footer.scss';

function Footer() {
    return (
        <footer className="bg-dark text-white text-center ">
            <div className="container">
                <div className="scrolling-content">
                    <img className="logo" src="/assets/logo_white.svg"/>
                    <p>&copy; {new Date().getFullYear()} Scholten Containerservice. All rights reserved.</p>
                    <ul className="list-inline">
                        <li className="list-inline-item"><a href="/impressum" className="text-white">Impressum</a></li>
                        <li className="list-inline-item"><a href="/kontakt" className="text-white">Kontakt</a></li>
                    </ul>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
