import InfoPage from "../../components/reusable/InfoPage";

const Kunststoff = () => {
    return (
        <>
            <InfoPage
                donts={{
                    headline: "Nicht erlaubt (Behandeltes Holz):",
                    points: ["Holz mit Anhaftungen", "Holzzäune", "Bahnschwellen", "Gartenmöbel", "Holzterrassen", "Rebpfähle", "Außentüren", "Fenster"]
                }}
                img={{src: "../assets/img/plastic.jpg", alt: "Holz&shy;abfälle"}}
                description={"Hierbei wird zwischen behandeltem und unbehandeltem Holz unterschieden. Unter unbehandeltem Holz versteht man:"}
                dos={{
                    headline: "Erlaubt (Unbehandeltes Holz):",
                    points: ["Naturbelassenes oder lediglich mechanisch bearbeitetes Altholz", "Paletten aus Vollholz", "Kanthölzer und Kisten", "Bauspanplatten", "Regale", "Schränke", "Deckenpaneele", "Furnierholz", "Holzbretter", "Pressspanplatten", "Massivholz"]
                }}
                title={"Kunststoff"}/>
        </>
    );
};

export default Kunststoff;
