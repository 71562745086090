import React from 'react';
import '../styles/ContainerFill.scss';

const ContainerFill = () => {
    return (
        <div className="container spacing-sectio container-fill">
            <h2 className="title">Container nicht über den Rand hinaus befüllen</h2>
            <div className="content">
                <p>Ein überfüllter Container kann zu folgenden Problemen führen:</p>
                <ul>
                    <li><strong>Verschütten:</strong> Der Inhalt kann beim Transport auslaufen.</li>
                    <li><strong>Beschädigung:</strong> Der Container könnte durch Überlastung kaputtgehen.</li>
                    <li><strong>Sicherheitsrisiko:</strong> Instabile Container können kippen oder stürzen.</li>
                    <li><strong>Entsorgungsprobleme:</strong> Überfüllte Container werden möglicherweise nicht abgeholt
                        oder richtig entleert.
                    </li>
                    <li><strong>Rechtliche Vorschriften:</strong> In einigen Fällen ist das Überfüllen gesetzlich
                        verboten.
                    </li>
                </ul>
                <p>Um Schäden und Komplikationen zu vermeiden, befüllen Sie Container immer nur bis zur angegebenen
                    Füllmenge.</p>
            </div>
            <img src="/assets/container-fill.svg" />
        </div>
    );
};

export default ContainerFill;
