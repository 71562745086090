import '../styles/WasteList.scss'
import {useEffect, useRef} from "react";

function WasteList() {
    const imageRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            const elements = document.querySelectorAll(".waste-item img");
            elements.forEach((element) => {
                const { top } = element.getBoundingClientRect();
                element.style.transform = `translateY(${top * 0.2}px)`;
            });
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, );

    const items = [
        {name: "Baumischabfall", image: "assets/img/baumischabfall.jpg", description: "Baumischabfall umfasst typische Baustellenabfälle", href: "info/baumischabfall"},
        {name: "Glas", image: "assets/img/glass.jpg", description: "Recycling&shy;fähiges Glas", href: "info/glass"},
        {name: "Papier", image: "assets/img/paper.jpg", description: "Papier und Karton", href: "info/papier"},
        {name: "Folie", image: "assets/img/foil.jpg", description: "Verpackungs&shy;folie", href: "info/folie"},
        {name: "Kunst&shy;stoff", image: "assets/img/plastic.jpg", description: "Plastik&shy;abfälle", href: "info/kunststoff"},
        {name: "Tapete&shy;nreste", image: "assets/img/wallpaper.jpg", description: "Reste von Tapeten", href: "info/tapetenreste"},
        {name: "Holz&shy;reste", image: "assets/img/wood.jpg", description: "Holz&shy;abfälle", href: "info/holzreste"},
        {name: "Metall", image: "assets/img/metall.jpg", description: "Metall&shy;abfälle", href: "info/metall"},
        {name: "Möbel", image: "assets/img/furniture.jpg", description: "Alte Möbel&shy;stücke", href: "info/möbel"},
        {name: "Gummi", image: "assets/img/rubber.jpg", description: "Gummi&shy;abfälle", href: "info/gummi"},
    ];
    return (
        <div className="wastelist">
            <div className=" container spacing-section">
                <h2 className="text-center mb-2">Misch&shy;abfall | Ge&shy;werbe&shy;abfall</h2>
                <h3 className="text-center mb-5">Das darf rein:</h3>
                <div className="row">
                    {items.map((item, index) => (
                        <div className="col-6 col-lg-4" key={index}>
                            <a className="waste-item" href={item.href}>
                                <img  ref={imageRef} src={item.image} alt={item.name}/>
                                <div className="text-container">
                                    <h3 dangerouslySetInnerHTML={{ __html: item.name }}></h3>
                                    <small dangerouslySetInnerHTML={{ __html: item.description }}></small>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default WasteList;