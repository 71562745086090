import PropTypes from 'prop-types';

function InfoPage({img, title, description, dos, donts}) {
    return (
        <>
            <img className="hero info-page-hero" src={img.src} alt={img.alt}/>
            <div className="container info-page">
                <h1>{title}</h1>
                <p className="short-info">{description}</p>
                <hr/>
                <div className="row">
                    <div className="list-container col-12 col-md-6">
                        <p className="list-heading">{dos.headline}</p>
                        <ul className="dos">
                            {dos.points.map((point, index) => (
                                <li key={index}>{point}</li>
                            ))}
                        </ul>
                    </div>
                    <div className="list-container col-12 col-md-6">
                        <p className="list-heading">{donts.headline}</p>
                        <ul className="donts">
                            {donts.points.map((point, index) => (
                                <li key={index}>{point}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}

InfoPage.propTypes = {
    img: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    descriptions: PropTypes.string,
    dos: PropTypes.object.isRequired,
    donts: PropTypes.object.isRequired,
};

export default InfoPage;
